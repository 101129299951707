import React from "react";
import { Modal, Box,Backdrop } from "@mui/material";

function Modalwrap(props) {
  const { open, close } = props;
  const handleClose = () => {
    close();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        onClick: event => {
          event.stopPropagation(); // Prevent click event from propagating
        },
      }}
    >
      <Box className={`modalCommon ${props?.style?.class}`}>{props?.component}</Box>
    </Modal>
  );
}

export default Modalwrap;
