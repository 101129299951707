import React from "react";
import WGCImg from "../../assets/styles/images/wgc.svg"
import SeminarImg from "../../assets/styles/images/seminar.svg"
import YoutubeImg from "../../assets/styles/images/youtube.svg"
import LineImg from "../../assets/styles/images/line.svg"
import AcademiaImg from "../../assets/styles/images/academia.svg"
import LinkIcon from "../../assets/styles/images/link-button.svg"


function AdminExternalLinks(props) {
  const closeModal = () => {
    props.close();
  };
  return (
    <>
      <div className="pop-head">
        <h5 className="pop-title" id="exampleModalLabel">
        組織開発お役立ちコンテンツ
        </h5>
        <button type="button" className="close" onClick={closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pd-pop">
      <div className="admin-links-item">
          <a href="https://www.coacha.com/wgc/" target="_blank" rel="noopener noreferrer">
            <img src={WGCImg} className="admin-links-item-img" alt="WGC" />
            WEEKLY GLOBAL COACH
            <img src={LinkIcon} className="admin-links-item-btn" alt="Link" />
          </a>
        </div>
        <div className="admin-links-item">
          <a href="https://www.coacha.com/seminar/" target="_blank" rel="noopener noreferrer">
            <img src={SeminarImg} className="admin-links-item-img" alt="Seminar" />
            セミナー・イベント
            <img src={LinkIcon} className="admin-links-item-btn" alt="Link" />
          </a>
        </div>
        <div className="admin-links-item">
          <a href="https://www.youtube.com/@coacha.youtube" target="_blank" rel="noopener noreferrer">
            <img src={YoutubeImg} className="admin-links-item-img" alt="YouTube" />
            公式Youtubeチャンネル
            <img src={LinkIcon} className="admin-links-item-btn" alt="Link" />
          </a>
        </div>
        <div className="admin-links-item">
          <a href="https://page.line.me/017ghnil?openQrModal=true" target="_blank" rel="noopener noreferrer">
            <img src={LineImg} className="admin-links-item-img" alt="Line" />
            公式LINE
            <img src={LinkIcon} className="admin-links-item-btn" alt="Link" />
          </a>
        </div>
        <div className="admin-links-item">
          <a href="https://coachacademia.com/" target="_blank" rel="noopener noreferrer">
            <img src={AcademiaImg} className="admin-links-item-img" alt="Academia" />
            コーチ・エィ アカデミア
            <img src={LinkIcon} className="admin-links-item-btn" alt="Link" />
          </a>
        </div>         
      </div>

    </>
  );
}

export default AdminExternalLinks;